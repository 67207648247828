import React, {Component} from 'react'
import {DropzoneAreaBase} from 'material-ui-dropzone'
import AvatarCrabber from 'static/images/icons/CRAB-02.png';
import './DropZone.css';
 
class CrabberDropzoneArea extends Component{
  constructor(props){
    super(props);
    this.state = {
      files: [],
      initialFiles: null,
      maxFileSize: 1 * 1024 * 1024,
      maxFileSizeLabel: '1 MiB',
      isMyStream: props.isMyStream
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleFileAddedMessage = this.handleFileAddedMessage.bind(this);
    this.handleFileRemovedMessage = this.handleFileRemovedMessage.bind(this);
    this.handleDropRejectMessage = this.handleDropRejectMessage.bind(this);
    this.handleFileLimitExceedMessage = this.handleFileLimitExceedMessage.bind(this);
  }
 
  

  componentDidMount() {
    this.setDefaultThumbnail();  
  }

  
  async setDefaultThumbnail() {
    let isMyStream = this.state.isMyStream;
    let thumbMyStream = window.FormIngestComponent.state.myStream.thumbnail;
    let response = await fetch(AvatarCrabber);
    try{
      if(isMyStream && thumbMyStream != null){
        response = await fetch(thumbMyStream);
      }

      reader.onloadend = (e) => {
        const data = e.target.result;
        const file = new File([data], 'thumb.jpg', { type: 'image/jpg' })
        const fileObject = { file: file, data: [data] }
        this.setState({
          files: [fileObject],
          initialFiles: [fileObject]
        })
      };
    }catch(e){
      console.log("Erro ao carregar thumbnail");
    }
    let data = await response.blob();
    var reader = new FileReader()

    if(!isMyStream){
      reader.onloadend = function(e) {
        window.FormIngestComponent.state.streamInfo.thumbnail =  e.target.result;
      };
    }

    reader.readAsDataURL(data);
  }

  handleChange(files){
    this.setState({
      files: files,
    });

    console.log(files);
    if (files && files.length > 0) {
      if(this.state.isMyStream){
        window.FormIngestComponent.state.myStream.thumbnail =  files[0].data;
      }else{
        window.FormIngestComponent.state.streamInfo.thumbnail =  files[0].data;
      }

    }
  }

  handleDelete() {
    this.setState({
        files: [],
    });
    this.setDefaultThumbnail(); 
    
  }

  handleFileAddedMessage(filename) {
    return "O thumbnail " + filename + " foi adicionado."
  }

  handleFileRemovedMessage(filename) {
    return "O thumbnail " + filename + " foi removido."
  }

  handleDropRejectMessage(fileObj) {
    if (!fileObj.type.includes("image")) {
        return "Ops, este arquivo não é uma imagem."
    }

    if (fileObj.size >= this.state.maxFileSize) {
        return "Ops, o arquivo não pode ultrapassar 1Mb."
    }

    return "Ops, arquivo invalido."
  }

  handleFileLimitExceedMessage(filesLimit) {
    return "Apenas " + filesLimit + " thumbnail pode ser adcionado.";
  }



  render(){
    const { onChange, onDelete } = this.props;
    const handleChange = onChange || this.handleChange;
    const handleDelete = onDelete || this.handleDelete;

    return (
      <DropzoneAreaBase
        maxFileSize={this.state.maxFileSize}
        showPreviews={false}
        showPreviewsInDropzone={true}
        previewGridClasses={{
            container: "custom-class"
        }
        }
        acceptedFiles={['image/*']}
        dropzoneText={"Arraste aqui a capa do canal de transmissão"}
        fileObjects={this.state.files}
        initialFiles={this.state.initialFiles}
        filesLimit={1}
        showAlerts={true}
        alertSnackbarProps = {{anchorOrigin: { vertical: 'top', horizontal: 'right' }}}
        getFileAddedMessage={(filename) => {return this.handleFileAddedMessage(filename)}}
        getFileRemovedMessage={(filename) => {return this.handleFileRemovedMessage(filename)}}
        getDropRejectMessage={(fileObj) => {return this.handleDropRejectMessage(fileObj)}}
        getFileLimitExceedMessage={(filesLimit) => {return this.handleFileLimitExceedMessage(filesLimit)}}
        onAdd={handleChange}
        onDelete={handleDelete}
        />
    )
  }
}
 
export default CrabberDropzoneArea;