import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withStyles} from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import {MyStreamApi, StopIngestApi} from 'config/config'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import UsageIngest from 'components/commons/UsageIngest';
import UsageStorage from 'components/commons/UsageStorage';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import { Redirect } from 'react-router-dom';
import Player from './Player'
import axios from 'interceptors';
import './Usage.css'
import NetworkCheck from "../commons/NetworkCheck";



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StopButton = withStyles((theme) => ({
    root: {
        padding: 2,
        marginTop: 9,
        marginLeft: 15,
        width: "6vh",
        height: "6vh",
        display: "none",
        color: theme.palette.getContrastText(red[900]),
        backgroundColor: red[900] + '99',
        '&:hover': {
            backgroundColor: red[900],
        },
    },
  }))(IconButton);


class FormIngest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            liveStatus: '',
            redirect: null,
            title: '', 
            open: false,
            openResponse: false,
            response: 'Sessão encerrada com sucesso!',
            intervalId: null,
        };
        this.timer = this.timer.bind(this);
        this.handleStopIngest = this.handleStopIngest.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseResponse = this.handleCloseResponse.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.loadMyStream = this.loadMyStream.bind(this);
    }


    handleOpenDialog() {
        this.setState({
            open: true,
        })
    }
    
    handleClose() {
        this.setState({
            open: false,
        })
    }

    handleCloseResponse() {
        //TODO fazer o set localStorage.getItem('@crabber/Player.liveStatus')
        this.setState({
            openResponse: false,
        });
        clearInterval(this.state.intervalId);
        this.setState({ 
            //redirect: "/studio",
            intervalId: null, 
        });
        
        if (!Player.hls) {
            return;
        }

        if (!Player.hls.levels) {
            return;
        }

        let duration = parseInt(Player.hls.levels[0].details.totalduration);
        let currentTime = duration - Math.min(300, duration);

        Player.hls.media.currentTime = currentTime;
        Player.hls.media.play();
    }

    handleStopIngest() {
        if(Player.hls && Player.hls.streamController && Player.hls.streamController.fragCurrent) {
            //console.log(Player.hls.streamController.fragCurrent.endPTS);
            //console.log(Player.hls.streamController.fragCurrent);
            //console.log(Player.hls.streamController);
        }
        
        // TODO enviar duraçaõ (quantos tempo foi usado)
        // ou ultimo nome de segmento carregado
        const requestOptions = {
            url: StopIngestApi.uri, 
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data:   {
                        id: JSON.parse(localStorage.getItem('@crabber/Ingest.subscriber')).id,
                    }
        };
        axios(requestOptions)
            .then(async response => {
                if (response.status !== 201) {
                    // get error message from body or default to response status
                    const data = response.data;
                    const error = (data && data) || response.status;
                    this.setState({
                        open: false,
                        response: error,
                        openResponse: true,
                    });
                    
                    return Promise.reject(error);
                } else {
                    this.setState({
                        open: false,
                        openResponse: true,
                        redirect: '/',
                    })
                    
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    componentDidMount() {
        var intervalId = setInterval(this.timer, 300);
        this.setState({
            intervalId: intervalId,
            title: JSON.parse(localStorage.getItem('@crabber/Ingest.ingestData')).title,
        });

        this.loadMyStream();
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
        this.setState({
            intervalId: null,
        });
    }

    timer() {
        this.setState({ 
            liveStatus: localStorage.getItem('@crabber/Player.liveStatus'),
        });
    }

    loadMyStream(){
        axios({
            url: MyStreamApi.uri,
            method: 'GET',
        }).then(response => {
            if (response.status === 200) {
                this.setState(prevState => ({
                    myStream: {
                        ...prevState.myStream,
                        title: response.data.title,
                        uploader: response.data.uploader,
                        thumbnail: response.data.thumbnail,
                        duration: response.data.duration,
                        server: response.data.server,
                        subscriberId: response.data.subscriberId,
                        endCaptureInActivity: response.data.endCaptureInActivity
                    }
                }));

                localStorage.setItem('@crabber/MyStream.subscriberId', response.data.subscriberId);
            }
        })
            .catch(error => {
                console.error(error);
            });
    }

    render() {
        // TODO talvez o certo seja o Studio chamar o redirect
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        const subscriberId = localStorage.getItem('@crabber/MyStream.subscriberId');
        const isMyStream = this.state.ingestId === subscriberId;

        return (
            <div>
                <Grid container alignContent='center' direction='row' justify='flex-start' className='usage-grid-container' >    
                    <Grid item xs={11} >
                        <TextField
                            variant="outlined" 
                            color="primary" 
                            margin="dense" 
                            label="Nome" 
                            fullWidth
                            disabled
                            value={this.state.title}
                        />
                    </Grid>

                    <Grid item xs={1} >

                        {localStorage.getItem('@crabber/Player.liveStatus') !== 'Sessão encerrada' &&
                        <StopButton 
                            onClick={() => { this.handleOpenDialog() }}>    
                            <StopRoundedIcon fontSize="large" size="large"/>
                        </StopButton>
                        }
                    </Grid>                 
                    <div className="timer">
                        {this.state.liveStatus !== 'Sessão encerrada' ? (<FiberManualRecordIcon className="blink"/>) : (<span></span>) }
                        <Typography variant="subtitle1" gutterBottom>
                                    {this.state.liveStatus}
                        </Typography>    
                    </div>
                </Grid>
                <div>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{isMyStream ? "Atenção!" : "Deseja parar a gravação?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {
                                    (isMyStream ?
                                            "Você está tentando encerrar uma gravação que foi iniciada pelo My Stream. Para finalizá-la, você deve interrompê-la no encoder. " :
                                            "Você perderá todos os clipes que não forem acervados. Deseja continuar?"
                                    )
                                }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                Cancelar
                            </Button>
                            <Button onClick={this.handleStopIngest} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                Sim
                            </Button>

                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openResponse}
                        onClose={this.handleCloseResponse}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{"Retorno"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {this.state.response}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseResponse} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        )
    }
}


function Usage(props) {
    return (
        <div>
            <div className='usage-container'>
                {
                    localStorage.getItem('@crabber/Ingest.ingestData') &&
                    <form noValidate autoComplete="off">
                        <div className='flex-grow1 centered'>
                            <Grid container alignContent='center' direction='row' justify='center' style={{ maxWidth: '100%' }}>
                                <Grid item xs={7} >
                                    <FormIngest />
                                </Grid>

                                <Grid item xs={1} style={{
                                    justifyContent: 'end',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <NetworkCheck />
                                </Grid>
                                <Grid item xs={2}>
                                    <UsageIngest />
                                </Grid>
                                <Grid item xs={2}>
                                    <UsageStorage />
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                }   
            </div>
            
        </div>
    )
}

export default Usage